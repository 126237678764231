import {
  AuthContext,
  GenericError,
  MenuContext,
} from '@monash/portal-frontend-common';
import {
  Icon,
  Sidebar,
  SidebarContainer,
  SidebarItem,
  SidebarSection,
} from '@monash/portal-react';
import React, { useContext } from 'react';
import ACCESS_ROLES from '../../constants/user-roles';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

const Admin = ({ children }) => {
  const { menu, handleMenu, closeMenu, menuRef, size } =
    useContext(MenuContext);
  const authCtx = useContext(AuthContext);

  const hasNotificationsAccess = [
    ACCESS_ROLES.NOTIFICATIONS_CRITICAL,
    ACCESS_ROLES.NOTIFICATIONS_NON_CRITICAL,
  ].some((x) => authCtx?.user?.roles?.includes(x));

  return (
    <SidebarContainer onLeft={true}>
      <Sidebar
        title="Admin"
        ifMenu={menu}
        toggleMenu={handleMenu}
        closeMenu={closeMenu}
        size={size}
        menuRef={menuRef}
      >
        <SidebarSection>
          <SidebarItem
            linkTo="/"
            text="Apps"
            icon={<Icon.Home />}
            onMenu={closeMenu}
          />
        </SidebarSection>
        <SidebarSection title="Global features">
          {authCtx?.user?.roles?.includes(ACCESS_ROLES.IMPERSONATION) && (
            <SidebarItem
              linkTo="/users"
              text="Users"
              icon={<Icon.User />}
              onMenu={closeMenu}
            />
          )}

          {hasNotificationsAccess && (
            <SidebarItem
              linkTo="/notifications"
              text="Notifications"
              icon={<Icon.Home />}
              onMenu={closeMenu}
            />
          )}

          <SidebarItem
            linkTo="/theme-builder"
            text="Theme builder"
            icon={<Icon.Adjustments />}
            onMenu={closeMenu}
          />

          {['dev', 'localhost'].some((x) =>
            window.location.host.includes(x)
          ) && (
            <SidebarItem
              linkTo="/api-docs"
              text="API Docs"
              icon={<Icon.Book />}
              onMenu={closeMenu}
            />
          )}
        </SidebarSection>
      </Sidebar>
      <SentryErrorBoundary fallback={<GenericError />}>
        {children}
      </SentryErrorBoundary>
    </SidebarContainer>
  );
};

export default Admin;
