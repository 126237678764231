import { useState, useContext } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Dropdown,
  InlineExternalLink,
  LoadingIndicator,
  SearchBox,
} from '@monash/portal-react';
import {
  getArchivedUpdates,
  getUserGroups,
  useApi,
} from '@monash/portal-frontend-common';
import ExpandableCard from '../../common/expandable-card/ExpandableCard';
import { byOrGroup, bySearchTerm } from '../updates/utils';
import {
  modifyCodesForCitizenship,
  removeCodeSuffix,
} from '../../common/user-groups-selector/utils';
import { formatDateRange } from '../../utilities/format-date';
import BlockErrorMessage from '../../common/block-error-message/BlockErrorMessage';
import { NO_FILTER_SELECTED_TEXT } from '../../../constants/text';
import c from '../updates/updates.module.scss';
import cs from '../../common/filter-dropdown/filter-dropdown.module.scss';
import { SlideOutContext } from '../../common/slide-out/SlideOutWrapper';
import UpdateForm from '../updates/update-form/UpdateForm';

export const Archives = () => {
  const [searchTarget, setSearchTarget] = useState('');
  const [orGroupFilter, setOrGroupFilter] = useState('');
  const [updatesList, setUpdatesList] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const { slide, setSlide, open, setOpen } = useContext(SlideOutContext);

  const userGroupDropdownList = [
    {
      text: NO_FILTER_SELECTED_TEXT,
      value: null,
    },
    ...modifyCodesForCitizenship(userGroups)?.map((item) => {
      return {
        text: `${removeCodeSuffix(item.code)} - ${item.name}`,
        value: item.code,
      };
    }),
  ];

  const { error: archivedUpdatesError, isLoading } = useApi({
    requestFn: getArchivedUpdates,
    onSuccess: (response) => {
      setUpdatesList(
        // Currently the archive endpoint returns the updates with their original statuses. Eventually it might return them with an "archived" status, in which case we can remove this map fn.
        response.map((update) => ({ ...update, status: 'archived' }))
      );
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const { error: userGroupsError } = useApi({
    requestFn: getUserGroups,
    args: ['updates'],
    onSuccess: (response) => {
      setUserGroups(response?.student);
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });

  const openSlideForm = (update) => {
    setSlide({
      content: (
        <UpdateForm
          existingUpdate={update}
          userGroups={userGroups}
          key={update ? JSON.stringify(update) : Math.random()}
          forceDisabled
        />
      ),
      data: update,
    });
    setOpen(true);
  };

  const renderContent = () => {
    if (archivedUpdatesError || userGroupsError) {
      return <BlockErrorMessage />;
    }

    return (
      <>
        <div className={c.searchWrapper}>
          <SearchBox searchString={searchTarget} onInput={setSearchTarget} />
        </div>

        <div className={c.filtersWrapper}>
          <Dropdown
            id="orGroupFilter"
            placeholder="User group"
            ariaLabelledby="orGroup"
            onChange={setOrGroupFilter}
            value={orGroupFilter}
            data={userGroupDropdownList}
            className={cs.customDropdown}
          />
        </div>

        <div className={c.cardsWrapper}>
          {updatesList
            .filter(bySearchTerm(searchTarget))
            .filter(byOrGroup(orGroupFilter))
            .sort((a, b) => {
              const latestSaveA = a.history[a.history.length - 1]?.time;
              const latestSaveB = b.history[b.history.length - 1]?.time;
              if (latestSaveA > latestSaveB) {
                return -1;
              } else {
                return 1;
              }
            })
            .map((update) => {
              const { id, description, startDate, endDate, ...restUpdate } =
                update;
              const label = formatDateRange(startDate, endDate);
              return (
                <ExpandableCard
                  onClick={() => {
                    openSlideForm(update);
                  }}
                  expandedContent={
                    <>
                      {description}

                      {update.links?.length ? (
                        <div className={c.links}>
                          {update.links.map(({ label, url }) => {
                            const textLabel = label || url;
                            return (
                              <InlineExternalLink
                                link={url}
                                text={textLabel}
                                className={c.link}
                                key={label}
                              />
                            );
                          })}
                        </div>
                      ) : null}
                    </>
                  }
                  label={label}
                  selected={open && slide?.data?.id === id}
                  {...restUpdate}
                  key={id}
                />
              );
            })}
        </div>
      </>
    );
  };

  return (
    <div className={c.container}>
      <div className={c.header}>
        <div className={c.headingGroup}>
          <h1>Archives</h1>
        </div>
      </div>

      {isLoading ? <LoadingIndicator /> : renderContent()}
    </div>
  );
};
